import { defineStore } from 'pinia';
import { annonConnect, checkSession, login, logout, register, update, updatePassword, remove, subscribe, unSubscribe, unSubscribeContent, payContent, recoverPassword, resetPassword, confirmAccount } from '@/api/auth';
import { postMemo } from '@/api/performer';
import type { User, UserForm, UpdatePassword, Anonymous } from '@/ontology/user';
import { useLocalizationStore } from './localization';
import { useAlertsStore } from './alerts';
import { usePerformerStore } from './performer';
import { usePurchaseStore } from './purchase';
import { useCamStore } from './cam';
import config from '@/config';
import notifications, { connect } from '@/socket';
import i18n from './../translations';
import type { Language } from '@/ontology/i18n';
import * as Sentry from '@sentry/vue';
import { getUnixTime } from 'date-fns';

type AuthenticationStatus = 'idle' | 'authenticating' | 'initializing' | 'authenticated' | 'updating';
interface State {
    status: AuthenticationStatus;
    ageOK: boolean;
    cookiesOK: boolean;
    userMenu: boolean;
    catMenu: boolean;
    filterMenu: boolean;
    safeMode: boolean;
    mobile: boolean;
    isSafari: boolean;
    paymentMethod: string | any;
    orientation: string | any;
    browserTimeout: any;
    confirmedType: string;
    confirmedErrorType: string;
    account: Partial<User>;
    checkInterval?: any;
    //lists the resolve fuctions of the promises made to those who want to know when authentication is complete
    authResolvers: (() => void)[];
    lastSendReport: number | undefined;
}

export interface PhotoPayload {
    serviceType: string;
    photoId: number;
}

const COOLDOWN_TIMEOUT_SEC = 60; // 60 seconds

export const useUserStore = defineStore('user', {
    state: (): State => ({
        status: 'idle',
        ageOK: false,
        cookiesOK: false,
        userMenu: false,
        catMenu: false,
        filterMenu: false,
        safeMode: false,
        mobile: false,
        isSafari: false,
        paymentMethod: 'phone',
        orientation: null,
        browserTimeout: null,
        confirmedType: '',
        confirmedErrorType: '',
        account: {
            roles: ['ROLE_UNKNOWN'],
            username: 'anonymous',
            totalNotifications: 0,
            client_details: undefined
        },
        checkInterval: undefined,
        authResolvers: [],
        lastSendReport: undefined
    }),
    actions: {
        init() {
            notifications.subscribe('credits', this.creditsChanged);
        },
        resizeBrowserHandler(e: any) {
            const camStore = useCamStore();
            if (this.browserTimeout) clearTimeout(this.browserTimeout);
            this.browserTimeout = setTimeout(() => {
                const touch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
                const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mac OS/i;
                this.mobile = touch && regex.test(navigator.userAgent);
                this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1;
                camStore.viewState = this.mobile ? 'fullscreen' : 'halfscreen';
                this.orientation = window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';
            }, 5);
        },
        async authenticate(visitFromPr0n: boolean, confirmation?: { userId: number; token: string }) {
            try {
                this.setStatus('authenticating');
                //scenario's:
                // - someone's here for the first time referred by ponrhub-like people
                // - someone's here for the first time on his own initiative
                // - someone's here by clicking the confirmation link in the confirmation email
                // - someone's not for the first time here

                // If the user is referred by a pr0n source,
                // assign default settings and disable autologin
                // to prevent overloading the API and socket
                if (visitFromPr0n) {
                    this.account = {
                        roles: ['ROLE_PR0N'],
                        username: i18n.global.t('account.anonymous'),
                        language: config.DefaultLanguage as Language
                    };

                    useLocalizationStore().initialize(this.account!.language!);
                    this.setStatus('authenticated');

                    return;
                }

                //if he tries confirming his account, do that first before going down the initialisation hassle
                if (confirmation) {
                    const { openMessage } = useAlertsStore();
                    const { error } = await this.confirm(confirmation.userId, confirmation.token);
                    if (error) {
                        this.confirmedType = 'failed';

                        switch (error.message) {
                            case 'Account is already validated.':
                                this.confirmedErrorType = 'validated';
                                break;
                            case 'Expired token.':
                                this.confirmedErrorType = 'expired';
                                break;
                            case 'Invalid token.':
                                this.confirmedErrorType = 'invalid';
                                break;
                            default:
                                this.confirmedErrorType = 'other';
                        }

                        const known = ['Account is already validated.', 'Expired token.', 'Invalid token.'];

                        //remove the '.' at the end to help with the translation..
                        const msg = known.includes(error.message) ? error.message.slice(0, -1) : 'Invalid token';

                        openMessage({
                            content: `account.alerts.errorConfirmRegister${msg}`,
                            class: 'error'
                        });
                    } else {
                        this.confirmedType = 'success';
                        openMessage({
                            content: 'account.alerts.successConfirmRegister',
                            class: 'success'
                        });
                    }
                }

                const { error: errorCheck, result: checked } = await checkSession();

                //we have seen this user before
                if (checked) {
                    this.account = checked;
                    if (this.account.roles![0] == 'ROLE_ANNON') {
                        this.account.username = window.localStorage.getItem('username') || i18n.global.t('account.anonymous');
                    }
                    notifications.sendLocalEvent('authentication', {
                        type: 'loggedin'
                    });
                }

                if (errorCheck && errorCheck.code != 403) {
                    //something went very wrong, very unexpectedly
                    //TODO:handle that error
                    Sentry.captureException(errorCheck);
                    return;
                }

                //we have not seen this user before!
                if (errorCheck && errorCheck.code == 403) {
                    const { error: errorAnnon, result: annon } = await annonConnect(config.Country);

                    if (errorAnnon) {
                        //TODO: handle that error
                        //somthing went very wrong, we did not expect that
                        Sentry.captureException(errorAnnon);
                        return;
                    }
                    this.account = this.annonToAccount(annon!);
                }

                this.initialize();
            } catch (error) {
                Sentry.captureException(error);
                console.error('Authentication error:', error);
            }
        },
        async confirm(userId: number, token: string) {
            const { error: errorCheck } = await checkSession();

            if (errorCheck && errorCheck.code != 403) {
                //something went very wrong, very unexpectedly
                //TODO:handle that error
                return { error: errorCheck };
            }

            //we have not seen this user before!
            if (errorCheck && errorCheck.code == 403) {
                const { error: errorAnnon, result: annon } = await annonConnect(config.Country);

                if (errorAnnon) {
                    //TODO: handle that error
                    //somthing went very wrong, we did not expect that
                    return { error: errorAnnon };
                }
                this.account = this.annonToAccount(annon!);
            }

            return await confirmAccount(userId, token);
        },
        annonToAccount(annon: Partial<User>): Partial<User> {
            return {
                ...annon,
                ...{
                    roles: ['ROLE_ANNON'],
                    username: i18n.global.t('account.anonymous')
                }
            };
        },
        //basically a function you can call if you need to wait until authentication is
        //complete. So you'd write 'await useUserStore().authentication()', which reads nicely. Admit it.
        async authentication() {
            if (this.status == 'authenticated') {
                return;
            }
            return new Promise<void>(resolve => this.authResolvers.push(resolve));
        },
        async recoverPassword(email: string) {
            const { error, result } = await recoverPassword(email);

            if (error) {
                return;
            }
        },
        async resetPassword(userId: number, password: string, token: string) {
            const { error, result } = await resetPassword(userId, password, token);

            if (error) {
                return error;
            }
        },
        async login(email: string, password: string, token: string) {
            if (navigator.webdriver) {
                return;
            }

            await this.ensureAtLeastAnnonymousAccount();

            if (!notifications.isConnected()) {
                await connect(config.SocketUrl, {
                    id: this.account.id!,
                    token: this.account.socketToken!,
                    type: 'ROLE_CLIENT'
                });
            }

            this.setStatus('authenticating');
            const checksession = await checkSession(); // Cookiefix

            const { error, result } = await login(email, password, token, 'ROLE_CLIENT');
            if (error) {
                //login failed; account is not changed
                this.setStatus('authenticated');
                return error;
            }

            this.account = result!;
            notifications.sendLocalEvent('authentication', {
                type: 'loggedin'
            });

            this.initialize();
        },
        async autoLogin() {
            console.log("userstore status confirmation complete, let's re-authenticate");
            await this.authentication();
            this.authenticate(false);
        },
        async register(user: UserForm) {
            await this.ensureAtLeastAnnonymousAccount();

            if (!notifications.isConnected()) {
                await connect(config.SocketUrl, {
                    id: this.account.id!,
                    token: this.account.socketToken!,
                    type: 'ROLE_CLIENT'
                });
            }

            //Set both values from localize store
            const local = useLocalizationStore();
            user.country = local.country;
            user.language = local.language;
            user.passwordconfirm = '';

            const { error } = await register(user);
            if (error) {
                //implement global error message
                return error;
            }
        },
        async ensureAtLeastAnnonymousAccount() {
            if (!this.account.roles?.includes('ROLE_PR0N')) {
                return;
            }

            const { error, result } = await annonConnect(config.Country);

            if (error) {
                //TODO: handle that error
                //somthing went very wrong, we did not expect that
                return { error };
            }
            this.account = this.annonToAccount(result!);
        },
        async logout() {
            if (this.account && this.account.roles![0] != 'ROLE_CLIENT') {
                //loggin out while not logged in makes no sense
                return;
            }

            const { error } = await logout();
            if (error) {
                useAlertsStore().openMessage({ content: i18n.global.t('modals.alerts.errorLogout'), class: 'error' });
                return;
            }

            this.setStatus('authenticating');
            //TODO: let's use the country the logged out user was using
            const { error: errorAnnon, result: annon } = await annonConnect(config.Country);

            if (errorAnnon) {
                //somthing went very wrong, we did not expect that
                return;
            }

            //TODO: remove all user specific data from the store. Like e.g. favorites

            this.account = annon!;
            this.account.username = i18n.global.t('account.anonymous');
            //I'm setting this role already, otherwise it won't be set until the next check_session
            this.account!.roles = ['ROLE_ANNON'];
            notifications.sendLocalEvent('authentication', {
                type: 'loggedout'
            });

            useAlertsStore().openMessage({ content: i18n.global.t('modals.alerts.successLogout'), class: 'success' });

            this.initialize();
        },
        async update(user: User) {
            this.setStatus('updating');
            const { error, result } = await update(user);

            if (error) {
                // error updating user
                return { error };
            }

            let rememberNotifies = this.account.totalNotifications;
            this.account = result!;
            this.account.totalNotifications = rememberNotifies;
            this.setStatus('authenticated');

            return { result };
        },
        async updatePassword(password: UpdatePassword, userId: number) {
            this.setStatus('updating');
            const { error, result } = await updatePassword(password, userId);

            if (error) {
                // error updating password
                return error;
            }

            this.setStatus('authenticated');
        },
        async remove(reason: Object, userId: number) {
            this.setStatus('updating');
            const { error, result } = await postMemo({
                content: JSON.stringify(reason),
                client: { id: userId }
            });

            if (error) {
                return;
            }

            const { error: error1, result: result1 } = await remove(userId);

            if (error1) {
                return;
            }

            this.setStatus('idle');
            this.logout();
        },
        async toggleSubscribe(userId: number, performerId: number) {
            this.setStatus('updating');

            const selectedPerformer = usePerformerStore().getById(performerId);
            const { error, result } = selectedPerformer.isSubscribed ? await unSubscribe(userId, performerId) : await subscribe(userId, performerId);

            if (error) {
                // error updating user
                return;
            }

            usePerformerStore().toggleSubscribed(performerId);
            this.setStatus('authenticated');
        },
        async unsubscribe(userId: number, token: string){
            const { error, result } = await unSubscribeContent(userId, token);
        },
        async payContent(userId: number, performerId: number, payload: PhotoPayload) {
            const { error, result } = await payContent(userId, performerId, payload);

            if (error) {
                // error updating user
                return error;
            }
        },
        async updateUserNotification(user: User, notify?: string) {
            this.setStatus('updating');

            if (notify) {
                user.notificationTypes = user.notificationTypes ? user.notificationTypes : { online: false, promotion: false, message: false };
                user.notificationTypes[notify] = user.notificationTypes[notify] ? false : true;
            }

            const { error, result } = await update(user);

            if (error) {
                // error updating user
                return;
            }

            this.account = result!;
            this.setStatus('authenticated');
        },
        //connects to the websocket and starts the checksession dance
        async initialize() {
            if (this.status != 'authenticating') {
                return;
            }

            //TODO: not awaiting anything between initializing and authenticated makes the intiializing status redundant
            this.setStatus('initializing');

            //Set correct locale from account data
            useLocalizationStore().initialize(this.account!.language!);

            //Get purchases store after correct localize settings are set
            usePurchaseStore().initialize();

            //connect to the notification server
            const { id, socketToken } = this.account!;
            connect(config.SocketUrl, {
                id: id!,
                token: socketToken!,
                type: 'ROLE_CLIENT'
            });

            await notifications.connection();

            //start the checksession dance
            this.startSessionCheck();
            this.setStatus('authenticated');

            //set payment method from localstorage
            this.setPaymentMethod();
        },
        startSessionCheck() {
            const oneMinute = 60 * 1000;
            if (this.checkInterval) {
                clearInterval(this.checkInterval);
            }

            this.checkInterval = setInterval(async () => {
                const { error, result } = await checkSession(false);

                //just in case someone is loggin in while check session is executing
                if (result && this.status == 'authenticated') {
                    result.creditsUntil = this.account.creditsUntil || 0;
                    this.account = result;

                    if (this.account.roles![0] == 'ROLE_ANNON') {
                        this.account.username = window.localStorage.getItem('username') || i18n.global.t('account.anonymous');
                    }
                }
                if (error) {
                    if (error.code == 403 && this.role() == 'ROLE_CLIENT') {
                        this.logout();
                    } else {
                        //TODO: handle this very unexpected error
                    }
                }
            }, oneMinute);
        },
        creditsChanged(update: { credits: number; creditsUntil: number; serverTime: number | string }) {
            const { credits, creditsUntil } = update;
            const serverTime = typeof update.serverTime == 'string' ? parseInt(update.serverTime) : update.serverTime;

            this.account.credits = credits;
            if (creditsUntil && creditsUntil > serverTime) {
                this.account.creditsUntil = creditsUntil - serverTime + Math.floor(Date.now() / 1000);
            } else {
                this.account.creditsUntil = undefined;
            }
        },
        setStatus(value: AuthenticationStatus) {
            if (value == this.status) {
                return;
            }

            this.status = value;

            if (value == 'authenticated') {
                this.authResolvers.forEach(resolve => {
                    resolve();
                });
                this.authResolvers = [];
            }
        },
        role() {
            if (this.account?.roles?.length) {
                return this.account.roles[0];
            } else {
                return 'ROLE_UNKNOWN';
            }
        },
        isMobile() {
            return this.mobile;
        },
        isLoggedIn() {
            return this.role() === 'ROLE_CLIENT';
        },
        isPaymentMethod() {
            const country = useLocalizationStore().country;
            return country !== 'cc' ? (this.isLoggedIn() ? this.paymentMethod : 'phone') : 'credits';
        },
        changePaymentMethod(value: string) {
            this.paymentMethod = value;
            if (this.isLoggedIn()) {
                window.localStorage.setItem(`${config.StorageKey}.paymentMethod`, value);
            }
        },
        setPaymentMethod() {
            if (window.localStorage.hasOwnProperty(`${config.StorageKey}.paymentMethod`)) {
                this.paymentMethod = localStorage.getItem(`${config.StorageKey}.paymentMethod`);
            }
        },
        toggleSafeMode() {
            this.safeMode = !this.safeMode;
        },
        toggleMobile() {
            this.mobile = !this.mobile;
        },
        toggleUserMenu() {
            this.catMenu = false;
            this.filterMenu = false;
            this.userMenu = !this.userMenu;
        },
        toggleCatMenu() {
            this.userMenu = false;
            this.filterMenu = false;
            this.catMenu = !this.catMenu;
        },
        toggleFilterMenu() {
            this.catMenu = false;
            this.userMenu = false;
            this.filterMenu = !this.filterMenu;
        },
        closeMenus() {
            this.catMenu = false;
            this.userMenu = false;
            this.filterMenu = false;
        },
        isTabletLandscape() {
            return this.orientation === 'landscape' && this.mobile;
        },
        updateReportSend() {
            this.lastSendReport = getUnixTime(new Date());
        },
        canSendReport() {
            if (!this.lastSendReport) {
                return true;
            }

            const currentTime = getUnixTime(new Date());
            const diffSec = currentTime - this.lastSendReport;

            return diffSec >= COOLDOWN_TIMEOUT_SEC;
        }
    }
});
