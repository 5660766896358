import type { PurchaseOptions } from '@/ontology/purchaseOptions';
import { defineStore } from 'pinia';
import { updateBalanceInfo } from '@/api/client/client_accounts.updatebalanceinfo';
import { useUserStore } from './user';
import config from '@/config';
import notifications from '@/socket';
import { useLocalizationStore } from './localization';

interface State extends PurchaseOptions {
    loading: boolean;
}

//let's see what else is needed here.. maybe some history?
export const usePurchaseStore = defineStore('purchase', {
    state: (): State => ({
        fees: [],
        packages: [],
        payment_methods: [],
        exchange: { name: 'EUR', rate: 1, symbol: '€' },
        cart: [],
        promo: undefined,
        loading: false
    }),
    actions: {
        async initialize() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            const { error, result } = await updateBalanceInfo();

            if (error) {
                //TODO: error handling
                return;
            }

            this.$patch({
                loading: false,
                ...result
            });

            this.setInitCart();

            notifications.subscribe('authentication', async (update: { type: 'loggedin' | 'loggedout' }) => {
                if (update.type !== 'loggedin') {
                    return;
                }
                if (this.loading) {
                    return;
                }
                //reload the balance info when logging in. The availabiliy of the credit card may just change
                this.loading = true;
                const { result } = await updateBalanceInfo();

                if (!result) {
                    return;
                }

                this.$patch({
                    loading: false,
                    ...result
                });
            });
        },
        setInitCart() {
            const userId = useUserStore().account.id;
            if (window.localStorage.getItem(`${config.StorageKey}.payment-cache-${userId}`) === null) {
                this.cart = this.packages!.map(pack => ({
                    id: pack.id,
                    amount: 0,
                    title: pack.title,
                    quantity: pack.quantity,
                    price: pack.price
                }));
                return;
            }

            const paymentCacheString = window.localStorage.getItem(`${config.StorageKey}.payment-cache-${userId}`);
            const paymentCache = JSON.parse(paymentCacheString as any);
            this.cart = paymentCache.packages;
        },
        updateCart(id: number, amount: number) {
            const pack = this.cart!.find(obj => obj.id === id);
            pack!.amount = amount;

            this.storeCartCache();
        },
        storeCartCache() {
            const userId = useUserStore().account.id!;
            window.localStorage.setItem(
                `${config.StorageKey}.payment-cache-${userId}`,
                JSON.stringify({
                    packages: this.cart
                })
            );
        },
        clearCart() {
            this.cart = this.packages!.map(pack => ({
                id: pack.id,
                amount: 0,
                title: pack.title,
                quantity: pack.quantity,
                price: pack.price
            }));

            const userId = useUserStore().account.id!;
            window.localStorage.removeItem(`${config.StorageKey}.payment-cache-${userId}`);
        },
        getCurrentAmount(id: number) {
            const current = this.cart!.find(obj => obj.id === id);
            return current ? current?.amount : 0;
        },
        getBonusAmount(amount: number) {
            const reversed = this.fees.slice().reverse();
            const fee = reversed.find((fee: any) => amount! >= fee.amount);
            const applicableBonus = fee ? fee.percentage : 0;
            return applicableBonus;
            //return Math.floor(credits! * (applicableBonus as any / 100));
        }
    },
    getters: {
        getCurrentExchange: state => {
            return state.exchange;
        },
        getBonus: state => {
            const credits = state.cart?.reduce((calc, obj) => {
                return calc + obj.quantity * obj.amount;
            }, 0);

            const reversed = state.fees.slice().reverse();
            const fee = reversed.find((fee: any) => credits! >= fee.amount);
            const applicableBonus = fee ? fee.percentage : 0;

            return Math.floor(credits! * ((applicableBonus as any) / 100));
        },
        getMethods: state => {
            const country = useLocalizationStore().country;
            //console.log('Country is', country);
            const creditcard = country ? config.AllowedCreditcardCountries.includes(country.toUpperCase()) : false;
            return creditcard ? state.payment_methods : state.payment_methods.filter(p => p.abbr !== 'VXSBILL'); //p.abbr != 'paysafe2/SKRILL' &&
            //return state.payment_methods.filter(p => p.abbr != 'PAYSAFE' && p.abbr !== 'VXSBILL')
        },
        getCartList: state => {
            const listPackages: any = [];
            if (!state.cart) {
                return [];
            }
            state.cart.forEach((pack: any) => {
                switch (pack.id) {
                    case 1:
                        if (pack.amount === 0 || listPackages.includes(pack.title)) {
                            return;
                        }
                        listPackages.push(pack.title);
                    case 2:
                        if (pack.amount === 0 || listPackages.includes(pack.title)) {
                            return;
                        }
                        listPackages.push(pack.title);
                    case 3:
                        if (pack.amount === 0 || listPackages.includes(pack.title)) {
                            return;
                        }
                        listPackages.push(pack.title);
                    case 4:
                        if (pack.amount === 0 || listPackages.includes(pack.title)) {
                            return;
                        }
                        listPackages.push(pack.title);
                    default:
                        console.log(`No result.`);
                }
            });
            return listPackages;
        }
    }
});
